<template>
  <div class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--os-dark">
            <div class="ph-inpage-widget__content ph-inpage-widget-max-250">
                <div class="ph-inpage-widget__thumb">
                <img :src="require('@/assets/panel/images/inpage/empty.svg')" v-show="!imgIcon" alt="" />
                <img :src="imgIcon" v-show="imgIcon" />  
                </div>
                <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-2">{{adTitle}}</div>
                <div class="ph-inpage-widget__btns-row">
                    <div class="ph-inpage-widget__btns-row-item">
                        <a class="ph-inpage-widget__btn">ОК</a>
                    </div>
                    <div class="ph-inpage-widget__btns-row-item">
                        <a class="ph-inpage-widget__btn">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>